import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  border-bottom: 2px solid #202731;
`;
const StatDiv = styled.div`
  display: flex;
`;
const Category = styled.div`
  display: flex;
  min-width: 80%;
  padding: 0.5em;
  font-size: 0.7em;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;
const Value = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 2px;
  align-items: center;
  font-size: ${(props) => (props.small ? ".8em" : "")};
  :hover {
    background-color: #db0a41;
  }
`;

export default function Stat({
  category,
  value,
  previewGraphic,
  game,
  player,
}) {
  return (
    <Main>
      <StatDiv>
        <Category
          onClick={() => {
            previewGraphic("PlayerStat", {
              player: player,
              subtext: value + " " + category,
              team: player?.team,
              game,
              category,
              value,
            });
          }}
        >
          {category}
        </Category>
        <Value
          small={category === "Possession"}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          {value}
        </Value>
      </StatDiv>
    </Main>
  );
}
