import React, { useContext } from "react";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { ScrollBar } from "../../Theme/Hyper";
import SeasonStat from "./SeasonStat";
const Main = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  overflow-y: auto;
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
`;

const Category = styled.div`
  min-width: 50%;
  font-size: 10px;
`;

const Stat = styled.div`
  width: 100%;
  font-size: 10px;
  text-align: center;
`;
export default function PlayerSeasonStats({ player, game }) {
  const { previewGraphic } = useContext(GraphicsContext);

  let stats =
    game?.home_player_stats?.[player.squad?._id] ||
    game?.away_player_stats?.[player.squad?._id] ||
    {};
  stats = JSON.parse(JSON.stringify(stats));
  let scorers = [
    ...(game?.home_scorers?.map((s) => {
      return { ...s, team: "home" };
    }) || []),
    ...(game?.away_scorers?.map((s) => {
      return { ...s, team: "away" };
    }) || []),
  ];

  let totals = 0;

  scorers
    .filter((s) => s.type === "try" && s?.player === player?.squad?._id)
    .forEach((player) => {
      totals += 1;
    });
  stats.tries = totals;

  let goals = 0;
  scorers
    .filter((s) => s.type === "conversion" && s?.player === player?.squad?._id)
    .forEach((player) => {
      goals += 1;
    });
  stats.goals = goals;

  let season_stats = player.squad?.stats;

  return (
    <Main>
      <Row>
        <Category>Category</Category>
        <Stat>Season</Stat>
        <Stat>Game</Stat>
        <Stat>Total</Stat>
      </Row>
      {Object.keys(season_stats || {})?.map((stat) => {
        return (
          <SeasonStat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={stat}
            season_value={season_stats[stat]}
            game_value={stats[stat]}
            game={game}
            player={player}
          />
        );
      })}
    </Main>
  );
}
