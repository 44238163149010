import React, { useContext } from "react";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { DataContext } from "../../contexts/DataContext";
import Stat from "./Stat";
import { ScrollBar } from "../../Theme/Hyper";
const Main = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  height: 800px;
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
`;

export default function TeamStatsUnion({ player, game }) {
  const { previewGraphic } = useContext(GraphicsContext);
  let home_stats = game?.home_team_stats || [];
  let away_stats = game?.away_team_stats || [];

  return (
    <Main>
      Attack
      <Stat
        game={game}
        category={"Clean Breaks"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: Math.round(home_stats?.attack?.cleanBreak) || 0,
          away: Math.round(away_stats?.attack?.cleanBreak) || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Defenders Beaten"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: Math.round(home_stats?.attack?.defenderBeaten) || 0,
          away: Math.round(away_stats?.attack?.defenderBeaten) || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Rucks Won %"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: Math.round(home_stats?.possession?.percentRucksWon) || 0,
          away: Math.round(away_stats?.possession?.percentRucksWon) || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Mauls Won"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.possession?.maulsWon || 0,
          away: away_stats?.possession?.maulsWon || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Offloads"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.attack?.offload || 0,
          away: away_stats?.attack?.offload || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Turnovers Won"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.defence?.turnoverWon || 0,
          away: away_stats?.defence?.turnoverWon || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Turnovers Conceded"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: away_stats?.defence?.turnoverWon || 0,
          away: home_stats?.defence?.turnoverWon || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      Defence
      <Stat
        game={game}
        category={"Tackles Made"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.defence?.tackle || 0,
          away: away_stats?.defence?.tackle || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Tackles Missed"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.defence?.missedTackle || 0,
          away: away_stats?.defence?.missedTackle || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Tackle success %"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: Math.round(home_stats?.defence?.percentTackleMade) || 0,
          away: Math.round(away_stats?.defence?.percentTackleMade) || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      Discipline
      <Stat
        game={game}
        category={"Penalties Conceded"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.discipline?.penaltyConceded || 0,
          away: away_stats?.discipline?.penaltyConceded || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Yellow Cards"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.discipline?.yellowCard || 0,
          away: away_stats?.discipline?.yellowCard || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Red Cards"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.discipline?.redCard || 0,
          away: away_stats?.discipline?.redCard || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      Possession
      <Stat
        game={game}
        category={"Possession %"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: Math.round(home_stats?.possession?.percentPossession) || 0,
          away:
            100 - (Math.round(home_stats?.possession?.percentPossession) || 0),
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      Set Pieces
      <Stat
        game={game}
        category={"Lineouts Won"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.lineout?.wonClean || 0,
          away: away_stats?.lineout?.wonClean || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Lineouts Lost"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.lineout?.lostClean || 0,
          away: away_stats?.lineout?.lostClean || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Lineout success %"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: Math.round(home_stats?.lineout?.percentLineoutsWon) || 0,
          away: Math.round(away_stats?.lineout?.percentLineoutsWon) || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Scrums Won"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.scrum?.scrumsWon || 0,
          away: away_stats?.scrum?.scrumsWon || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Scrums Lost"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.scrum?.scrumsLost || 0,
          away: away_stats?.scrum?.scrumsLost || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Scrum Success %"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.scrum?.percentScrumsWon || 0,
          away: away_stats?.scrum?.percentScrumsWon || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Restarts Won"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.restart?.restartRetained || 0,
          away: away_stats?.restart?.restartRetained || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Restarts Lost"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: away_stats?.restart?.restartRetained || 0,
          away: home_stats?.restart?.restartRetained || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
      <Stat
        game={game}
        category={"Restart Success %"}
        matchStats={[]}
        previewGraphic={(name, options) => {
          previewGraphic(name, {
            ...options,
          });
        }}
        stat={{
          home: home_stats?.restart?.percentRestartsRetained || 0,
          away: away_stats?.restart?.percentRestartsRetained || 0,
        }}
        updateHomeStat={({ e }) => {}}
        updateAwayStat={({ e }) => {}}
      />
    </Main>
  );
}
