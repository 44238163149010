import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { setContext } from "@apollo/client/link/context";
import Startup from "./Startup";
import { getMainDefinition } from "@apollo/client/utilities";
import { UserProvider } from "./contexts/UserContext";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

let wsURI = window.ENV?.REACT_APP_RUGBY_API || process.env.REACT_APP_RUGBY_API;
wsURI = wsURI.replace("http", "ws");
wsURI = wsURI.replace("https", "wss");

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${wsURI}/graphql`,
    options: {
      reconnect: true,
    },
  })
);

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_RUGBY_API || process.env.REACT_APP_RUGBY_API) +
    "/graphql",
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <UserProvider>
        <Startup />
      </UserProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
