import React, { useContext } from "react";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { DataContext } from "../../contexts/DataContext";
import Stat from "./Stat";
import { ScrollBar } from "../../Theme/Hyper";
const Main = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 739px;
  overflow-y: auto;
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
`;

export default function PlayerStats({ player, game }) {
  return game?.home_player_stats?.[0]?.id ? (
    <PlayerStatsOval player={player} game={game} />
  ) : (
    <PlayerStatsHyper player={player} game={game} />
  );
}

function PlayerStatsHyper({ player, game }) {
  const { previewGraphic } = useContext(GraphicsContext);

  let stats =
    game?.home_player_stats?.[player.squad?._id] ||
    game?.away_player_stats?.[player.squad?._id] ||
    {};
  return (
    <Main>
      {Object.keys(stats)?.map((stat) => {
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={stat}
            value={stats[stat]}
            game={game}
            player={player}
          />
        );
      })}
    </Main>
  );
}

function PlayerStatsOval({ player, game }) {
  const { previewGraphic } = useContext(GraphicsContext);

  let stats =
    game?.home_player_stats?.find((p) => p.id === player.oval_ID)?.stats ||
    game?.away_player_stats?.find((p) => p.id === player.oval_ID)?.stats ||
    {};

  function convertCat(cat) {
    const result = cat.replace(/([A-Z])/g, " $1");
    let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    if (finalResult.indexOf("Percent") > -1) {
      finalResult = finalResult.replace("Percent ", "");
      finalResult = finalResult + " %";
      finalResult = finalResult.replace("Tackle Made", "Tackles Made");
    }
    return finalResult;
  }
  return (
    <Main>
      {Object.keys(stats?.attack || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.attack?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
      {Object.keys(stats?.defence || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.defence?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
      {Object.keys(stats?.discipline || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.discipline?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
      {Object.keys(stats?.kicking || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.kicking?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
      {Object.keys(stats?.lineout || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.lineout?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
      {Object.keys(stats?.restart || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.restart?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
      {Object.keys(stats?.scoring || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.scoring?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
    </Main>
  );
}
